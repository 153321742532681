import React, { useContext, useEffect, useState } from "react";

import { Container, Row, Col, Button, Collapse } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import ProdRecomShowCase from "../components/ProdRecomShowCase";
import Differentiation from "../components/Differentiation";
import ProdRecomFeatures from "../components/ProdRecomFeatures";
import TransDashboard from "../components/TransDashboard";

import objectHero from "../assets/extra/Products/product-recommendation.svg";

import ogImage from "../assets/extra/global/link-logo-white.png";

import Seo from "../components/Seo.js";
import TransCTA from "../components/TransCTA";
import ProdRecomApiDemo from "../components/APIDemos/ProdRecomApiDemo";
import GlobalContext from "../context/GlobalContext";
import ProdRecomDemoV2 from "../components/APIDemos/ProdRecomDemoV2";
import APIDemo from "../components/APIDemos/APIDemo";
import OtherCTA from "../components/OtherCTA";
import { Link } from "gatsby";

const ProdRecom = () => {
  const [openItem, setOpenItem] = useState(1);
  const imgurl = `https://genify.ai${ogImage}`;

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        url: "https://genify.ai/",
        path: "/recommendation-api",
        page_type: "productPage",
        event: "page_view",
        title: "Product Recommendation API",
      });
    }
  }, []);

  return (
    <>
      <Seo
        title="Personalized Product Recommendation API | Genify"
        description="Personalized product & action recommendations enable banks to recommend the right product to the right customer at the right time. Explore Genify's Recommendation API. "
        image={imgurl}
        url="https://www.genify.ai/recommendation-api/"
      />

      <PageWrapper
        headerConfig={{
          theme: "light",
          align: "right",
          variant: "primary",
          isFluid: true,
          source: "genify",
          button: "null",
          path: "productPage",
        }}
        footerConfig={{
          style: "style1",
        }}
      >
        <div
          className=" dark-mode-texts pt-13 pt-md-25 pb-13 pb-md-25 mx-md-6 rounded-10 "
          style={{ backgroundColor: "#fff" }}
        >
          <>
            {/* <!-- Hero Area --> */}
            <div
              className="hero-area position-relative  pt-15 pt-lg-33  pb-lg-35 pb-lg-35"
              style={{ backgroundColor: "#fff" }}
            >
              <Container className="landing-large">
                <Row className="justify-content-center  align-items-center">
                  <Col
                    lg="6"
                    md="6"
                    sm="12"
                    xs="12"
                    className="order-lg-2 mt-9 mt-lg-0 pl-md-25"
                    data-aos="fade-right"
                    data-aos-duration="500"
                    data-aos-once="true"
                  >
                    <div
                      className="hero-content dark-mode-texts landing-text"
                      style={{ width: "90%" }}
                    >
                      <h1
                        className="title gr-text-2 mb-7"
                        style={{
                          color: "rgb(74, 99, 165)",
                          fontSize: "clamp(2.5rem,4vw,4rem)",
                        }}
                      >
                        Product Recommendation API{" "}
                      </h1>

                      <p
                        className="gr-text-9 pr-5 pl-0 pr-xl-11 py-10"
                        style={{
                          textAlign: "left",
                        }}
                      >
                        With Genify’s personalized product and action
                        recommendation API, you can recommend relevant and
                        personalized banking products to the right client at the
                        right time, across all your channels.
                      </p>
                      <div
                        className="hero-btn pb-10"
                        style={{ zIndex: "9999", textAlign: "left" }}
                      >
                        <Link
                          to="/contact-us"
                          className="gr-text-color gr-hover-text-white"
                        >
                          <Button
                            // href="contact-us/#contactform"
                            className="gr-hover-y"
                            style={{ backgroundColor: "#ff565b", border: "0" }}
                            onClick={() =>
                              window.dataLayer.push({
                                page_type: "productPage",
                                event: "contact_us",
                              })
                            }
                          >
                            Talk to us{" "}
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </Col>
                  <Col
                    lg="6"
                    md="6"
                    className="order-lg-1 order-sm-1 order-xs-1  "
                  >
                    <div
                      className="test-landing"
                      data-aos="fade-left"
                      data-aos-duration="750"
                      data-aos-delay="500"
                    >
                      <object
                        className=" main-object  zoom-product-1-trans"
                        data={objectHero}
                        aria-labelledby="trans product"
                        alt=""
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </>

          <div style={{ backgroundColor: "#fff" }} className="pt-15 pb-10">
            <Container>
              <Row className="align-items-center">
                <Col
                  md="10"
                  lg="12"
                  xl="6"
                  className="offset-xl-1 drawer mt-9 mt-lg-0 order-lg-2 order-md-2 order-sm-2 order-xs-2"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                  // style={{ maxWidth: "30vw" }}
                >
                  <div
                    className="accordion pl-9 pr-8 rounded-10  border-top-5"
                    id="accordionExample"
                    style={{
                      backgroundColor: "rgb(74, 99, 165)",
                      borderBlockColor: "#ff565b",
                    }}
                  >
                    <div className="border-bottom overflow-hidden">
                      <div className="mb-0 border-bottom-0" id="heading2-1">
                        <button
                          className="btn-reset gr-text-6 font-weight-bold text-left  px-0 py-8 accordion-trigger arrow-icon w-100 collapsed"
                          type="button"
                          aria-controls="collapse2-1"
                          onClick={() => setOpenItem(1)}
                          aria-expanded={openItem === 1}
                          style={{ color: "#fff" }}
                        >
                          Banking Product Recommendations
                        </button>
                      </div>

                      <Collapse in={openItem === 1}>
                        <div>
                          <div className="gr-color-white-opacity-7 mt-n3 gr-text-10 pb-8 pr-8">
                            Recommend banking products among 12 standard
                            products.
                          </div>
                        </div>
                      </Collapse>
                    </div>
                    <div className="border-bottom overflow-hidden">
                      <div className="mb-0 border-bottom-0" id="heading2-2">
                        <button
                          className="btn-reset gr-text-6 font-weight-bold text-left text-white px-0 py-8 accordion-trigger arrow-icon w-100 collapsed"
                          type="button"
                          data-target="#collapse2-2"
                          onClick={() => setOpenItem(2)}
                          aria-expanded={openItem === 2}
                        >
                          E-Commerce Product Recommendations
                        </button>
                      </div>

                      <Collapse in={openItem === 2}>
                        <div>
                          <div className="gr-color-white-opacity-7 mt-n3 gr-text-10 pb-8 pr-8">
                            Personalized product recommendation among 10
                            e-commerce product categories.
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </div>
                </Col>
                <Col
                  xs="12"
                  sm="12"
                  md="12"
                  lg="5"
                  className="order-lg-1 order-md-1 order-sm-1 order-xs-1 mt-sm-20"
                >
                  <div className=" mt-12 mt-md-0">
                    <div className="info-label">
                      Personalized Recommendations
                    </div>
                    <h2
                      className="gr-text-4 mb-7 justify-content-center"
                      style={{ color: "rgb(74, 99, 165)" }}
                    >
                      Boost cross-selling with personalized product
                      recommendations.
                    </h2>
                    <p
                      className="gr-text-9 gr-text-color-opacity"
                      style={{
                        lineHeight: "1.8",
                        letterSpacing: "-0.2px",
                      }}
                    >
                      Genify's advanced recommendation engine leverages user
                      behavior data and machine learning to generate banking and
                      e-commerce recommendations to your clients at their
                      various touchpoints.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div
          className="inner-banner pt-20 pb-11  border-top-5"
          style={{ backgroundColor: "rgb(74, 99, 165)" }}
        >
          <Container>
            <Row className="justify-content-center pt-5">
              <Col lg="9" xl="8">
                <div className="px-md-15 text-center">
                  <h2
                    className="title gr-text-2 mb-9"
                    style={{ color: "#fff" }}
                  >
                    Features
                  </h2>
                </div>
              </Col>
            </Row>
          </Container>
          <ProdRecomFeatures />
        </div>
        <div className="inner-banner pt-15 pb-md-11 bg-default-1">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col lg="9" xl="8">
                <div className="px-md-15 text-center">
                  <h2 className="title gr-text-2 mb-9" style={{}}>
                    Genify Dashboard
                  </h2>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <TransDashboard />
        <div className="inner-banner pt-15 pb-md-11 bg-default-1">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col lg="9" xl="8">
                <div className="px-md-15 text-center">
                  <h2 className="title gr-text-2 mb-9" style={{}}>
                    Showcase
                  </h2>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <ProdRecomShowCase />
        <div
          className="inner-banner pt-15 bg-default-1 pb-10   border-top-5"
          style={{ backgroundColor: "rgb(74, 99, 165)" }}
        >
          <Container>
            <Row className="justify-content-center pt-5">
              <Col lg="9" xl="8">
                <div className="px-md-15 text-center">
                  <h2
                    className="title gr-text-2 mb-9"
                    style={{ color: "#fff" }}
                  >
                    Why Genify?
                  </h2>
                </div>
              </Col>
            </Row>
          </Container>
          <Differentiation />
        </div>
        <div
          className="inner-banner pt-20 pb-20"
          style={{ backgroundColor: "rgb(245, 247, 250)" }}
        >
          <Container>
            <Row className="justify-content-center pt-5">
              <Col lg="9" xl="8">
                <div className="px-md-15 text-center">
                  <h2 className="title gr-text-2 mb-9" style={{}}>
                    How Does Genify’s Recommendation API Work?
                  </h2>
                </div>
              </Col>
            </Row>
          </Container>
          <APIDemo selected="recom" exclusive />
        </div>
        <OtherCTA />
      </PageWrapper>
    </>
  );
};

export default ProdRecom;
