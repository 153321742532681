import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
const ProdRecomApiDemo = () => {
	const handleResponseSubmit = () => {
		var twoToneButton = document.querySelector('.twoToneButton')
		var terminalInputRequest = document.querySelector('.terminal-input-request')
		var terminalInputResponse = document.querySelector('.terminal-input-response-recommendation-api')
		var fakeScreen = document.querySelector('.fakeScreen')
		var refresh = document.querySelector('#refresh')

		twoToneButton.innerHTML = 'Running'
		twoToneButton.classList.add('spinning')
		terminalInputRequest.classList.add('hidden')
		setTimeout(function() {
			twoToneButton.classList.remove('spinning')
			twoToneButton.innerHTML = 'Run'
			terminalInputResponse.classList.remove('hidden')
			terminalInputResponse.classList.remove('hidden')
			twoToneButton.classList.add('hidden')
			fakeScreen.style.minHeight = '450px'
			twoToneButton.disabled = true
			refresh.classList.remove('hidden')
		}, 2000)
	}
	const handleRefresh = () => {
		var twoToneButton = document.querySelector('.twoToneButton')
		var refresh = document.querySelector('#refresh')
		var refreshBtn = document.querySelector('#refresh-btn')
		var fakeScreen = document.querySelector('.fakeScreen')

		var terminalInputRequest = document.querySelector('.terminal-input-request')
		var terminalInputResponse = document.querySelector('.terminal-input-response-recommendation-api')

		refreshBtn.innerHTML = 'Refreshing'
		refreshBtn.style.width = '10em'
		refreshBtn.classList.add('spinning')
		setTimeout(function() {
			refreshBtn.classList.remove('spinning')
			twoToneButton.innerHTML = 'Run'
			refreshBtn.style.width = '7.07em'

			terminalInputResponse.classList.add('hidden')
			terminalInputRequest.classList.remove('hidden')
			twoToneButton.classList.remove('hidden')
			refresh.classList.add('hidden')
			refreshBtn.innerHTML = 'Refresh'
			fakeScreen.style.minHeight = '400px'

			twoToneButton.disabled = false
		}, 2000)
	}
	return (
		<Container className='pb-30'>
			<Row className='mt-10'>
				<Col md='6'>
					<div className='fakeMenu'>
						<div className='fakeButtons fakeClose'></div>
						<div className='fakeButtons fakeMinimize'></div>
						<div className='fakeButtons fakeZoom'></div>
					</div>
					<div className='fakeScreen'>
						<div className='terminal-input-request' style={{ position: 'relative' }}>
							<code>
								<div className='fragment'>
									<span style={{ color: '#79e7f4' }}>{'{'}</span>
									<br />
								</div>
								{'       '}
								<div className='container-tab'>
									<div className='fragment'>
										<span style={{ color: '#79e7f4' }}>&quot;user_id&quot;:</span>{' '}
										<span style={{ color: '#b0ec6d' }}>&quot;105086&quot;,</span>
										<br />
									</div>{' '}
									<div className='fragment'>
										<span style={{ color: '#79e7f4' }}>&quot;age&quot;:</span>{' '}
										<span style={{ color: '#b0ec6d' }}>&quot;46&quot;,</span>
										<br />
									</div>{' '}
									<div className='fragment'>
										<span style={{ color: '#79e7f4' }}>&quot;gender&quot;:</span>{' '}
										<span style={{ color: '#b0ec6d' }}>Male,</span>
										<br />
									</div>{' '}
									<div className='fragment'>
										<span style={{ color: '#79e7f4' }}>&quot;nationality&quot;:</span>{' '}
										<span style={{ color: '#b0ec6d' }}>Brazil,</span>
										<br />
									</div>{' '}
									<div className='fragment'>
										<span style={{ color: '#79e7f4' }}>&quot;seniority&quot;:</span>{' '}
										<span style={{ color: '#b0ec6d' }}>214,</span>
										<br />
									</div>{' '}
									<div className='fragment'>
										<span style={{ color: '#79e7f4' }}>&quot;rel_type&quot;:</span>{' '}
										<span style={{ color: '#b0ec6d' }}>&quot;A&quot;,</span>
										<br />
									</div>{' '}
									<div className='fragment'>
										<span style={{ color: '#79e7f4' }}>&quot;activity_level&quot;:</span>{' '}
										<span style={{ color: '#b0ec6d' }}>&quot;A&quot;,</span>
										<br />
									</div>{' '}
									<div className='fragment'>
										<span style={{ color: '#79e7f4' }}>&quot;income&quot;:</span>{' '}
										<span style={{ color: '#b0ec6d' }}>93794.88,</span>
										<br />
									</div>{' '}
									<div className='fragment'>
										<span style={{ color: '#79e7f4' }}>&quot;segment&quot;:</span>{' '}
										<span style={{ color: '#b0ec6d' }}>&quot;02 - PARTICULARES&quot;,</span>
										<br />
									</div>{' '}
									<div className='fragment'>
										<span style={{ color: '#79e7f4' }}>&quot;region&quot;:</span>{' '}
										<span style={{ color: '#b0ec6d' }}>&quot;ES&quot;</span>
										<br />
									</div>{' '}
								</div>{' '}
								<div className='fragment'>
									<span style={{ color: '#79e7f4' }} data-v-5d621296 data-v-0bd6bc83>
										{'}'}
									</span>
								</div>
							</code>
						</div>
						<div className='terminal-input-response-recommendation-api hidden'>
							<code>
								<div className='fragment'>
									<span style={{ color: '#79e7f4' }}>{'{'}</span>
									<br />
								</div>
								{'       '}
								<div className='container-tab'>
									<div className='fragment'>
										<span style={{ color: '#79e7f4' }}>&quot;products&quot;:</span>{' '}
										<span style={{ color: '#b0ec6d' }}>[</span>
										<br />
									</div>{' '}
									<div className='fragment'>
										<span style={{ color: '#79e7f4' }}>&quot;Credit Card&quot;:</span>{' '}
										<span style={{ color: '#b0ec6d' }}> 0.4539159834,</span>
										<br />
									</div>{' '}
									<div className='fragment'>
										<span style={{ color: '#79e7f4' }}>&quot;Taxes&quot;:</span>{' '}
										<span style={{ color: '#b0ec6d' }}> 0.2271945328,</span>
										<br />
									</div>{' '}
									<div className='fragment'>
										<span style={{ color: '#79e7f4' }}>&quot;Current Accounts&quot;:</span>{' '}
										<span style={{ color: '#b0ec6d' }}> 0.0676045567,</span>
										<br />
									</div>{' '}
									<div className='fragment'>
										<span style={{ color: '#79e7f4' }}>&quot;Long-term deposits&quot;:</span>{' '}
										<span style={{ color: '#b0ec6d' }}> 0.0377641022,</span>
										<br />
									</div>{' '}
									<div className='fragment'>
										<span style={{ color: '#79e7f4' }}>&quot;Direct Debit&quot;:</span>{' '}
										<span style={{ color: '#b0ec6d' }}> 0.0202699713,</span>
										<br />
									</div>{' '}
									<div className='fragment'>
										<span style={{ color: '#79e7f4' }}>&quot;Particular Account&quot;:</span>{' '}
										<span style={{ color: '#b0ec6d' }}> 0.0146606928,</span>
										<br />
									</div>{' '}
									<div className='fragment'>
										<span style={{ color: '#79e7f4' }}>&quot;Particular Plus Account&quot;:</span>{' '}
										<span style={{ color: '#b0ec6d' }}> 0.0137294559,</span>
										<br />
									</div>{' '}
									<div className='fragment'>
										<span style={{ color: '#79e7f4' }}>&quot;Funds&quot;:</span>{' '}
										<span style={{ color: '#b0ec6d' }}> 0.0133901061,</span>
										<br />
									</div>{' '}
									<div className='fragment'>
										<span style={{ color: '#79e7f4' }}>&quot;Payroll Account&quot;:</span>{' '}
										<span style={{ color: '#b0ec6d' }}> 0.0131766619,</span>
										<br />
									</div>{' '}
									<div className='fragment'>
										<span style={{ color: '#79e7f4' }}>&quot;e-account&quot;:</span>{' '}
										<span style={{ color: '#b0ec6d' }}> 0.0124733746,</span>
										<br />
									</div>{' '}
								</div>{' '}
								<div className='fragment'>
									<span style={{ color: '#b0ec6d' }} data-v-5d621296 data-v-0bd6bc83>
										{']'}
									</span>
								</div>
								<div className='fragment'>
									<span style={{ color: '#79e7f4' }} data-v-5d621296 data-v-0bd6bc83>
										{'}'}
									</span>
								</div>
							</code>
						</div>
					</div>
					<div className='fakeScreenFooter'>
						<div className='screenLanguage'>json</div>
						<div className='twoToneCenter '>
							<button className='twoToneButton gr-hover-y' onClick={handleResponseSubmit}>
								Run
							</button>
						</div>
						<div className='twoToneCenter hidden' id='refresh'>
							<button className='twoToneButton gr-hover-y ' id='refresh-btn' onClick={handleRefresh}>
								Refresh
							</button>
						</div>
					</div>
				</Col>
				<Col xs='11' sm='12' md='6' lg='6'>
					<div className='pl-lg-3 pl-xl-12 mt-12 mt-md-0 '>
						<h2 className='gr-text-2 mb-7' style={{ fontSize: '2rem' }}>
							Recommend Products
						</h2>
						<p className='gr-text-9 gr-text-color-opacity'>
							Try recommending banking products to a user. The API endpoint takes as input user metadata
							like age or job, and their history of products with you (current account, debit card, etc.).
							Genify's recommender system algorithm returns a ranking of the most relevant banking
							products, each with a relevancy score (probability).
						</p>
					</div>
				</Col>
			</Row>
		</Container>
	)
}

export default ProdRecomApiDemo
