import React, { useState } from "react";
import { Row, Col, Container, Fade } from "react-bootstrap";
import transparency from "../assets/image/svg/transparency.svg";
import usersegmentation from "../assets/image/svg/usersegmentation.svg";
import pfm from "../assets/image/svg/pfm.svg";
import future from "../assets/image/svg/future.svg";

const ProdRecomShowcase = () => {
  const [active, setActive] = useState(1);

  return (
    <div className="pb-md-25  pt-md-10  ">
      <Container>
        <nav className="pb-12 ">
          <div
            className="nav gr-nav-tabs"
            id="nav-tab"
            role="tablist"
            style={{
              flexWrap: "nowrap",
              overflowX: "scroll",
              whiteSpace: "nowrap",
              margin: "auto",
              marginBottom: "0",
            }}
          >
            <div style={{ display: "flex", margin: "auto" }}>
              <div
                data-aos="fade-right"
                data-aos-duration="700"
                data-aos-delay="400"
                style={{ position: "relative", marginRight: "10px" }}
              >
                <a
                  className={`nav-item nav-link gr-text-9 font-weight-bold ${
                    active === 1 ? "active" : ""
                  }`}
                  role="tab"
                  aria-selected={active === 1}
                  href="/#"
                  onClick={(e) => {
                    e.preventDefault();
                    setActive(1);
                  }}
                  // data-aos="fade-up"
                  // data-aos-duration="700"
                  // data-aos-delay="800"
                >
                  Boost Topline
                </a>
              </div>
              <div
                data-aos="fade-right"
                data-aos-duration="1000"
                data-aos-delay="400"
                style={{ position: "relative", marginRight: "10px" }}
              >
                <a
                  className={`nav-item nav-link gr-text-9 font-weight-bold ${
                    active === 2 ? "active" : ""
                  }`}
                  role="tab"
                  aria-selected={active === 2}
                  href="/#"
                  onClick={(e) => {
                    e.preventDefault();
                    setActive(2);
                  }}
                  // data-aos="fade-up"
                  // data-aos-duration="700"
                  // data-aos-delay="1200"
                >
                  User Segmentation
                </a>
              </div>
              <div
                data-aos="fade-right"
                data-aos-duration="1300"
                data-aos-delay="400"
                style={{ position: "relative", marginRight: "10px" }}
              >
                <a
                  className={`nav-item nav-link gr-text-9 font-weight-bold ${
                    active === 3 ? "active" : ""
                  }`}
                  role="tab"
                  aria-selected={active === 3}
                  href="/#"
                  onClick={(e) => {
                    e.preventDefault();
                    setActive(3);
                  }}
                  // data-aos="fade-up"
                  // data-aos-duration="700"
                  // data-aos-delay="1400"
                >
                  PFM
                </a>
              </div>
              <div
                data-aos="fade-right"
                data-aos-duration="1600"
                data-aos-delay="400"
                style={{ position: "relative", marginRight: "10px" }}
              >
                <a
                  className={`nav-item nav-link gr-text-9 font-weight-bold ${
                    active === 4 ? "active" : ""
                  }`}
                  role="tab"
                  aria-selected={active === 4}
                  href="/#"
                  onClick={(e) => {
                    e.preventDefault();
                    setActive(4);
                  }}
                  // data-aos="fade-up"
                  // data-aos-duration="700"
                  // data-aos-delay="1600"
                >
                  Future Proof
                </a>
              </div>
            </div>
          </div>
        </nav>
        <div className="tab-content " id="nav-tabContent">
          <Fade
            in={active === 1}
            role="tabpanel"
            className={`tab-pane ${active === 1 ? "active" : ""}`}
          >
            <div>
              <div>
                <Row>
                  <Col md="6">
                    <p className="gr-text-9   showcase-adjust ">
                      Make your banking mobile app future-proof with AI.
                    </p>
                  </Col>
                  <Col md="6">
                    <div className="widget-icon gr-text-4 text-blue   mb-7 mb-md-0">
                      <object
                        className="showcase-adjust-image"
                        data={transparency}
                        aria-labelledby="future proof"
                        alt="feature"
                        style={{}}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Fade>

          <Fade
            in={active === 2}
            role="tabpanel"
            className={`tab-pane ${active === 2 ? "active" : ""}`}
          >
            <div>
              <div>
                <Row>
                  <Col md="6" sm="12" xs="12">
                    <p className="gr-text-9   showcase-adjust">
                      Know your users better from their transaction data.
                    </p>
                  </Col>
                  <Col md="6">
                    <div className="widget-icon gr-text-4 text-blue   mb-7 mb-md-0">
                      <object
                        className="showcase-adjust-image-2"
                        aria-labelledby="know your users"
                        data={usersegmentation}
                        alt="feature"
                        style={{ paddingBottom: "53px" }}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Fade>
          <Fade
            in={active === 3}
            role="tabpanel"
            className={`tab-pane ${active === 3 ? "active" : ""}`}
          >
            <div>
              <div>
                <Row>
                  <Col md="6" sm="12" xs="12">
                    <p className="gr-text-9   showcase-adjust">
                      Bring true PFM features to your banking app.
                    </p>
                  </Col>

                  <Col md="6">
                    <div className="widget-icon gr-text-4 text-blue   mb-7 mb-md-0">
                      <object
                        className="showcase-adjust-image"
                        aria-labelledby="true pfm"
                        data={pfm}
                        alt="feature"
                        style={{}}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Fade>
          <Fade
            in={active === 4}
            role="tabpanel"
            className={`tab-pane ${active === 4 ? "active" : ""}`}
          >
            <div>
              <div>
                <Row>
                  <Col md="6">
                    <p className="gr-text-9   showcase-adjust">
                      Make your banking mobile app future-proof with AI.
                    </p>
                  </Col>
                  <Col md="6">
                    <div className="widget-icon gr-text-4 text-blue   mb-7 mb-md-0">
                      <object
                        className="showcase-adjust-image"
                        data={future}
                        aria-labelledby="future proof"
                        alt="feature"
                        style={{}}
                      />
                      {/* done */}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Fade>
        </div>
      </Container>
    </div>
  );
};

export default ProdRecomShowcase;
